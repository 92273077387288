<template>
	<v-container>
		<v-row class="justify-center">
			<v-col cols="18" sm="8">
				<h1 class="display-2 font-weight-bold mb-3">
					Waar het allemaal <span class="text--gradient--green">begon</span>
				</h1>

				<p class="subheading font-weight-regular">
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
					incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
					nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
				</p>

				<v-timeline>
					<v-timeline-item v-for="(year, i) in years" :key="i" :color="year.color" small>
						<template v-slot:opposite>
							<span
								:class="`headline font-weight-bold ${year.color}--text`"
								v-text="year.year"
							></span>
						</template>
						<div class="py-4">
							<h2 :class="`headline font-weight-regular mb-2 ${year.color}--text`">
								Lorem ipsum
							</h2>
							<div>
								Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola
								imperdiet nec ut, sed euismod convenire principes at. Est et nobis
								iisque percipit, an vim zril disputando voluptatibus, vix an
								salutandi sententiae.
							</div>
						</div>
					</v-timeline-item>
				</v-timeline>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	name: 'About',
	data: () => ({
		years: [
			{
				color: 'green',
				year: '1980'
			},
			{
				color: 'amber',
				year: '1990'
			},
			{
				color: 'orange',
				year: '2000'
			}
		]
	})
}
</script>
