<template>
	<v-container>
		<v-row>
			<v-col cols="18" sm="6">
				<v-img class="image-pattern tilt-right-1 mr-5" src="/images/solar_panel.jpg" />
			</v-col>
			<v-col cols="18" sm="6" align-self="center">
				<h1 class="display-2 font-weight-bold mb-3">
					Samen
					<span class="text--gradient--green">€20.592</span> voor sociale energie verdiend
				</h1>

				<p class="subheading font-weight-regular">
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
					incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
					nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
				</p>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	name: 'Landing',
	data: () => ({})
}
</script>
