<template>
	<v-container>
		<v-dialog v-model="dialog" persistent max-width="600px">
			<v-card>
				<v-card-title>
					<span class="headline">Meld een project aan</span>
				</v-card-title>
				<v-card-text class="pb-2">
					<h3>Contactgegevens</h3>
					<v-row class="my-0">
						<v-col class="pt-0">
							<v-text-field
								v-model="form.contact.name"
								prepend-icon="mdi-account"
								hide-details
								label="Naam"
							/>
						</v-col>
					</v-row>
					<v-row class="my-0">
						<v-col class="pt-0">
							<v-text-field
								v-model="form.contact.email"
								prepend-icon="mdi-email"
								hide-details
								label="Mailadres"
							/>
						</v-col>
						<v-col class="pt-0">
							<v-text-field
								v-model="form.contact.phone"
								prepend-icon="mdi-phone"
								hide-details
								label="Telefoonnummer"
							/>
						</v-col>
					</v-row>
					<template v-if="form.type === 'social'">
						<h3 class="mt-3">Gegevens Sociaal Project</h3>
						<v-row class="my-0">
							<v-col class="pt-0">
								<v-text-field
									v-model="form.project.name"
									hide-details
									label="Project Naam"
								/>
							</v-col>
						</v-row>
						<v-row class="my-0">
							<v-col class="pt-0">
								<v-text-field
									v-model="form.project.street"
									hide-details
									label="Straatnaam"
								/>
							</v-col>
							<v-col cols="3" class="pt-0">
								<v-text-field
									v-model="form.project.streetNr"
									hide-details
									label="Nummer"
								/>
							</v-col>
						</v-row>
						<v-row class="my-0">
							<v-col class="pt-0">
								<v-text-field
									v-model="form.project.postalCode"
									hide-details
									label="Postcode"
								/>
							</v-col>
							<v-col class="pt-0">
								<v-text-field
									v-model="form.project.city"
									hide-details
									label="Stad"
								/>
							</v-col>
						</v-row>
						<v-row class="my-0">
							<v-col class="pt-0">
								<v-textarea
									v-model="form.project.description"
									counter="300"
									max="300"
									label="Beschrijving"
								/>
							</v-col>
						</v-row>
						<v-row class="my-0">
							<v-col class="pt-0">
								<v-text-field
									v-model="form.project.website"
									hide-details
									label="Website"
								/>
							</v-col>
							<v-col cols="4" class="pt-0">
								<v-text-field
									v-model="form.project.targetPrice"
									hide-details
									label="Streefbedrag"
								/>
							</v-col>
						</v-row>
					</template>
					<template v-else>
						<h3 class="mt-3">Gegevens PV Project</h3>
						<v-row class="my-0">
							<v-col class="pt-0">
								<v-text-field
									v-model="form.project.name"
									hide-details
									label="Project Naam"
								/>
							</v-col>
						</v-row>
						<v-row class="my-0">
							<v-col class="pt-0">
								<v-text-field
									v-model="form.project.street"
									hide-details
									label="Straatnaam"
								/>
							</v-col>
							<v-col cols="3" class="pt-0">
								<v-text-field
									v-model="form.project.streetNr"
									hide-details
									label="Nummer"
								/>
							</v-col>
						</v-row>
						<v-row class="my-0">
							<v-col class="pt-0">
								<v-text-field
									v-model="form.project.postalCode"
									hide-details
									label="Postcode"
								/>
							</v-col>
							<v-col class="pt-0">
								<v-text-field
									v-model="form.project.city"
									hide-details
									label="Stad"
								/>
							</v-col>
						</v-row>
						<v-row class="my-0">
							<v-col class="pt-0">
								<v-select
									v-model="form.project.status"
									:items="items.projectStatus"
									hide-details
									label="Status"
								/>
							</v-col>
						</v-row>
						<v-row class="my-0">
							<v-col class="pt-0">
								<v-text-field
									v-model="form.project.capacity"
									suffix="kWpiek"
									hide-details
									label="Vermogen"
								/>
							</v-col>
							<v-col class="pt-0">
								<v-text-field
									v-model="form.project.expectedOutput"
									suffix="kWh/jaar"
									hide-details
									label="Verwachte Opwek"
								/>
							</v-col>
						</v-row>
						<v-row class="my-0">
							<v-col class="pt-0">
								<v-select
									v-model="form.project.projectType"
									:items="items.projectType"
									hide-details
									label="Type Project"
								/>
							</v-col>
						</v-row>
					</template>
				</v-card-text>
				<v-card-actions class="py-4">
					<v-btn color="light-green lighten-4" elevation="0" @click="dialog = false">
						Project aanmelden
					</v-btn>
					<v-spacer></v-spacer>
					<v-btn text @click="dialog = false">
						Annuleer
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-row>
			<v-col cols="18" sm="6">
				<h1 class="display-2 font-weight-bold mb-2">
					Sociale Energie
				</h1>
				<p class="subheading font-weight-medium mb-6">
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
					incididunt ut labore et dolore magna aliqua.
				</p>
				<div class="mt-5">
					<v-btn href="https://client.se.scp.spectral.energy" elevation color="white">
						<span class="mr-2">Login</span>
						<v-icon>mdi-open-in-new</v-icon>
					</v-btn>
				</div>
			</v-col>
			<v-col cols="18" sm="6" align-self="center">
				<h3 class="headline font-weight-bold mb-2">
					Doe mee
				</h3>
				<p class="subheading font-weight-medium">
					Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
					deserunt mollit anim id est laborum.
				</p>
				<v-btn elevation outlined color="white" @click="openSocialProjectForm">
					Meld een sociaal project aan
				</v-btn>
				<v-btn class="ml-3" elevation outlined color="white" @click="openPVProjectForm">
					Meld een PV project aan
				</v-btn>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	name: 'Contact',
	data: () => ({
		dialog: false,
		items: {
			type: [
				{ text: 'Sociaal Project', value: 'social' },
				{ text: 'PV Project', value: 'pv' }
			],
			projectStatus: [
				{ text: 'Bestaand', value: 'existing' },
				{ text: 'Nog te ontwikkelen', value: 'pending' }
			],
			projectType: [
				{ text: 'SDE+', value: 'sdeplus' },
				{ text: 'Subsidieregeling Coöperatieve Energieopwekking', value: 'subsidy' },
				{ text: 'Saldering', value: 'saldering' }
			]
		},
		form: {
			type: 'social',
			contact: {
				name: '',
				email: '',
				phone: ''
			},
			project: {
				name: '',
				street: '',
				streetNr: '',
				city: '',
				postalCode: '',
				status: 'pending',
				capacity: '',
				expectedOutput: '',
				projectType: 'sdeplus',
				description: '',
				website: '',
				targetPrice: 0
			}
		}
	}),
	methods: {
		openSocialProjectForm() {
			this.dialog = true
			this.form.type = 'social'
		},
		openPVProjectForm() {
			this.dialog = true
			this.form.type = 'pv'
		}
	}
}
</script>
