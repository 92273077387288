<template>
	<v-container>
		<v-row>
			<v-col>
				<h1 class="display-2 font-weight-bold mb-3">
					Partners van <span class="text--gradient--green">Sociale Energie</span>
				</h1>

				<p class="subheading font-weight-regular">
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
					incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
					nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
				</p>
			</v-col>
		</v-row>
		<v-row>
			<v-col
				v-for="(project, index) in projects"
				:key="index"
				class="d-flex child-flex"
				cols="4"
			>
				<v-img aspect-ratio="2" class="grey lighten-3 d-flex align-center justify-center">
					<v-img
						:title="project.name"
						:src="project.src"
						:lazy-src="project.src"
						max-height="120"
						contain
						class="ma-5"
					>
						<template v-slot:placeholder>
							<v-row class="fill-height ma-0" align="center" justify="center">
								<v-progress-circular
									indeterminate
									color="grey lighten-5"
								></v-progress-circular>
							</v-row>
						</template>
					</v-img>
				</v-img>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	name: 'About',
	data: () => ({
		years: [
			{
				color: 'green',
				year: '1980'
			},
			{
				color: 'amber',
				year: '1990'
			},
			{
				color: 'orange',
				year: '2000'
			}
		],
		projects: [
			{
				name: 'Spectral Energy',
				src: '/images/logo/spectral.png'
			},
			{
				name: 'Greenchoice',
				src: '/images/logo/greenchoice.png'
			},
			{
				name: 'Gemeente Amsterdam',
				src: '/images/logo/gemeente-amsterdam.png'
			}
		]
	})
}
</script>
