<template>
	<v-app class="custom-background">
		<v-app-bar class="custom-header" app flat color="white">
			<v-container class="py-0 fill-height">
				<div class="d-flex align-center">
					<h1 class="headline font-weight-black">
						<span class="text--gradient--green">Sociale Energie</span>
					</h1>
					<div class="ml-5 d-none d-md-flex">
						<v-btn
							v-for="(button, index) of buttons"
							:key="index"
							:href="`#${button.id}`"
							text
							class="button-fade mr-2"
							:class="{ 'light-green lighten-4': button.id === intersecting }"
							@click="goToSection(`#${button.id}`)"
						>
							{{ button.text }}
						</v-btn>
					</div>
				</div>

				<v-spacer></v-spacer>

				<v-btn href="https://client.se.scp.spectral.energy" text>
					<span class="mr-2">Login</span>
					<v-icon>mdi-open-in-new</v-icon>
				</v-btn>
			</v-container>
		</v-app-bar>

		<v-main>
			<v-main class="py-16 grey lighten-4">
				<div id="landing" v-intersect="intersect" />
				<section-landing />
			</v-main>
			<v-main class="py-16 white">
				<div id="about" v-intersect="intersect" />
				<section-about />
			</v-main>
			<v-main class="py-16 grey lighten-4">
				<div id="partners" v-intersect="intersect" />
				<section-partners />
			</v-main>
			<v-main class="py-16 green-section">
				<div id="contact" v-intersect="intersect" />
				<section-contact />
			</v-main>
		</v-main>
	</v-app>
</template>

<script>
import SectionLanding from './sections/Landing'
import SectionAbout from './sections/About'
import SectionPartners from './sections/Partners'
import SectionContact from './sections/Contact'

export default {
	name: 'App',
	components: {
		SectionLanding,
		SectionAbout,
		SectionPartners,
		SectionContact
	},
	data: () => ({
		intersecting: 'landing',
		buttons: [
			{
				id: 'landing',
				text: 'Home'
			},
			{
				id: 'about',
				text: 'Over'
			},
			{
				id: 'partners',
				text: 'Partners'
			},
			{
				id: 'contact',
				text: 'Doe Mee'
			}
		]
	}),
	computed: {
		intersect() {
			return {
				handler: this.onIntersect,
				options: {
					threshold: [0, 0.5, 1.0]
				}
			}
		}
	},
	methods: {
		goToSection(section) {
			this.$vuetify.goTo(section, {
				duration: 350,
				offset: 64,
				easing: 'easeInOutCubic'
			})
		},
		onIntersect(entries) {
			const target = entries[0]
			if (target.isIntersecting) this.intersecting = target.target.id
		}
	}
}
</script>
